/* CSS for News */
.btnConatiner {
  display: flex;
  justify-content: space-between;
  /* justify-content: flex-end; */
  align-items: center;
  margin-bottom: 15px;
}

.searchInput {
  width: 340px;
  height: 45px;
  border-radius: 8px;
  border: 1px solid #aaa;
  font-size: 16px;
  padding: 0px 15px 0px 40px;
}

.searchInput::placeholder {
  color: #aaa;
}

.searchInput:focus {
  outline: none;
  border: 1px solid #aaa;
}

.searchIcon {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--primary-color);
}

.toFromDateBtn {
  width: 60px;
  height: 25px;
  background-color: #ddd;
  cursor: pointer;
  font-size: 10px;
  border-radius: 10px;
  margin-right: 10px;
  border: none;
}

.toFromDateBtn:hover {
  background-color: #ccc;
}

.refreshBtn {
  width: 60px;
  height: 45px;
  background-color: var(--primary-color);
  cursor: pointer;
  border-radius: 8px;
  border: none;
  margin-right: 5px;
  color: white;
  font-size: 16px;
}

.refreshBtn:hover {
  background-color: #007233;
}

.btnContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.btn {
  width: 140px;
  height: 45px;
  background-color: var(--primary-color);
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 8px;
  margin: 0px 0px 15px 0px;
}

.addNewsBtn {
  width: 140px;
  height: 45px;
  background-color: var(--primary-color);
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 8px;
  margin: 0px;
}

.addNewsBtn:hover {
  background-color: #007233;
}

.newsCardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.modalNewsCardContainer {
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-height: 400px;
  background-color: #fff;
}

.newsCard {
  flex: 0 0 calc(50% - 20px);
  /* 50% for 2 cards in a row, 20px for some space between the cards */
  max-width: calc(50% - 20px);
  box-sizing: border-box;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
}

.newsCard:hover {
  background-color: #eee;
}

.newsCardContent {
  padding: 10px;
}

.slogname {
  color: var(--primary-color);
  margin: 0px 0px 5px 0px;
  font-size: 14px;
  cursor: pointer;
}

.company {
  color: #333;
  margin: 0px 0px 5px 0px;
  font-size: 14px;
  cursor: pointer;
}

.category {
  color: #858585;
  font-size: 12px;
  margin: 0px 0px 4px 0px;
}

/* .headline {
  margin: 0px 0px 10px 0px;
  font-size: 15px;
} */

.subheadline {
  margin: 0px 0px 10px 0px;
  font-size: 15px;
  color: rgba(8, 7, 7, 0.668);
}

.dateTimeAndScripCode {
  margin: 6px 0px 0px 0px;
  display: inline-block;
  font-size: 12px;
  background-color: #3fd984;
  padding: 5px 10px 5px 10px;
  border-radius: 20px;
}

.historicalBtn {
  margin: 6px 14px 0px 0px;
  display: inline-block;
  font-size: 12px;
  background-color: #f29452;
  padding: 5px 10px 5px 10px;
  border-radius: 20px;
  cursor: pointer;
}

.positionsBtn {
  margin: 6px 14px 0px 0px;
  display: inline-block;
  font-size: 12px;
  background-color: #bec923;
  padding: 5px 10px 5px 10px;
  border-radius: 20px;
  cursor: pointer;
}

/* Add News Modal form style */
.label {
  display: block;
  margin-bottom: 10px;
}

.input {
  display: block;
  width: 100%;
  height: 45px;
  padding: 8px;
  border: 2px solid #aaaaaa;
  border-radius: 8px;
  box-sizing: border-box;
  margin-top: 5px;
  font-size: 18px;
  color: var(--primary-color);
}

.input:focus {
  outline: none;
  border: 2px solid var(--primary-color);
}

.input::placeholder {
  color: #b6bbc4;
}

.input[disabled] {
  background-color: #dff0d8;
  color: #888;
  cursor: not-allowed;
  border: 2px solid #ccc;
}

.inputDropdown {
  /* width: 100%; */
  height: 45px;
  padding: 8px;
  border: 1px solid #aaaaaa;
  border-radius: 8px;
  box-sizing: border-box;
  margin-top: 5px;
  font-size: 18px;
  color: var(--primary-color);
}

.inputDropdown:focus {
  outline: none;
  border: 1px solid var(--primary-color);
}

.NoNewsText {
  font-size: 25px;
  color: #858585;
  margin: 20px 0px 20px 0px;
  text-align: center;
}

.positionsBtn {
  border: none;
  outline: none;
}

.historicalBtn {
  border: none;
  outline: none;
}

/* Global News Styling */
.GnewsCard {
  width: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

.GnewsCard:hover {
  background-color: #eee;
}

.Gslogname {
  color: var(--primary-color);
  margin: 0px 0px 5px 0px;
  font-size: 20px;
  cursor: pointer;
}

.Gcategory {
  color: #858585;
  font-size: 16px;
  margin: 0px 0px 4px 0px;
}

.GdateTimeAndScripCode {
  margin: 6px 0px 0px 0px;
  display: inline-block;
  font-size: 14px;
  background-color: #3fd984;
  padding: 5px 10px 5px 10px;
  border-radius: 20px;
}

.GhistoricalBtn {
  margin: 6px 14px 0px 0px;
  display: inline-block;
  font-size: 14px;
  background-color: #f29452;
  padding: 5px 10px 5px 10px;
  border-radius: 20px;
  cursor: pointer;
  color: #2d3250;
}

.GhistoricalBtn {
  border: none;
  outline: none;
}

/* toggle button style */
.toggleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 240px;
}

.toggleSwitch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  margin: 0 10px;
}

.toggleSwitch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggleSlider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.toggleSlider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked+.toggleSlider {
  background-color: var(--primary-color);
}

input:checked+.toggleSlider:before {
  transform: translateX(25px);
}