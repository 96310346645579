@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  margin: 0;

  /* fonts */
  font-family: "Roboto", sans-serif;
  color: #2d3250;
}

:root {
  --primary-color: #059346;
  --sidebar-width-open: 250px;
  --sidebar-width-closed: 50px; /* Adjust based on your closed sidebar width */
}

/* Global Loader Style */
.GlobalLoader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Customize the scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 10px;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background-color: #eee;
  border-radius: 8px;
}

.MainDiv {
  display: flex;
  height: 100vh;
}

.MainDivContent {
  width: calc(100% - var(--sidebar-width));
  margin-left: var(--sidebar-width);
  padding: 20px;
  overflow: auto;
  position: relative;
  transition: margin-left 0.5s ease, width 0.5s ease;
}

.MainDivContentClosed {
  margin-left: var(--sidebar-width);
  width: calc(100% - var(--sidebar-width-closed));
}

/* Table styling */
table {
  border-collapse: collapse;
  width: 100%;
}

thead {
  background-color: #f2f2f2;
}

thead th {
  text-align: left;
  font-weight: bold;
  border-bottom: 2px solid #ddd;
  padding: 8px 12px 8px 12px;
}

thead th:last-child {
  padding-right: 0px;
}

tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

tbody tr:hover {
  background-color: #e0e0e0;
}

tbody td {
  border-bottom: 1px solid #ddd;
  padding: 8px 12px 8px 12px;
}

td {
  /* Ensure content fits on a single line and truncates overflow */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}