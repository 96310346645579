.btnContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 15px;
  /* background-color: red; */
}

.addBtn {
  width: 140px;
  height: 45px;
  background-color: var(--primary-color);
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 8px;
  margin: 0px 10px 0px 0px;
}

.addBtn:hover {
  background-color: #007233;
}

.deleteBtn {
  width: 140px;
  height: 45px;
  background-color: #f44336;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 8px;
  margin: 0px;
}

.deleteBtn:hover {
  background-color: #ca372c;
}

.deleteBtn:disabled {
  background-color: #f44336c7;
  cursor: not-allowed;
}

/* Add Fund Map Modal form style */
.label {
  display: block;
  margin-bottom: 10px;
}

.input {
  display: block;
  width: 100%;
  height: 45px;
  padding: 8px;
  border: 2px solid #aaaaaa;
  border-radius: 8px;
  box-sizing: border-box;
  margin-top: 5px;
  font-size: 18px;
  color: var(--primary-color);
}

.input:focus {
  outline: none;
  border: 2px solid var(--primary-color);
}

.input::placeholder {
  color: #b6bbc4;
}
