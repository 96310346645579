.ModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.Modal {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.ModalBody {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.ModalTextQuestion {
  margin: 0px 0px 10px 0px;
}

.ChildrenContainer {
  margin-bottom: 10px;
  background-color: #fff;
}

.line {
  display: block;
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin: 0px 0px 10px 0px;
}

.ButtonContainer {
  margin-top: auto;
}

.ConfirmButton,
.CancelButton {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  cursor: pointer;
  margin-right: 20px;
}

.ConfirmButton {
  background-color: #4caf50;
  color: white;
}

.CancelButton {
  background-color: #f44336;
  color: white;
}