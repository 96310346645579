.btnSearchContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.searchContainer {
  position: relative;
}

.searchInput {
  width: 340px;
  height: 45px;
  border-radius: 8px;
  border: 1px solid #aaa;
  font-size: 16px;
  padding: 0px 15px 0px 40px;
}

.searchInput::placeholder {
  color: #aaa;
}

.searchInput:focus {
  outline: none;
  border: 1px solid #aaa;
}

.searchIcon {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--primary-color);
}

.btnContainer {
  display: flex;
  align-items: center;
}

.btn {
  width: 140px;
  height: 45px;
  background-color: var(--primary-color);
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 8px;
  margin: 0px 15px 0px 0px;
}

.btn:last-child {
  margin-right: 0px;
}

.btn:hover {
  background-color: var(--primary-color);
}

.btnActive {
  width: 140px;
  height: 45px;
  background-color: #555;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 8px;
  margin: 0px 15px 0px 0px;
}

.btnActive:last-child {
  margin-right: 0px;
}

.btnActive:hover {
  background-color: #555555d1;
}

.tablePaddingContainer {
  /* padding: 10px; */
  border: var(--primary-color) solid 2px;
  border-radius: 8px;
  margin-top: 10px;
  height: calc(100% - 125px);
  overflow: auto;
}

.tableContainer {
  /* background-color: red; */
  /* height: calc(100% - 120px); */
  overflow: auto;
}

.tableRow {
  cursor: pointer;
}

.tableRow:hover {
  color: var(--primary-color);
  text-decoration: underline;
}

.noRecordTr {
  height: 200px;
  border: 0px;
}

.noRecordTr:hover {
  background-color: transparent;
}

.noRecordsOnSearch {
  text-align: center;
  color: var(--primary-color);
  font-size: 28px;
  margin-top: 20px;
  font-weight: 600;
  border: 0px;
}
