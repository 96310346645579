.fullbody {
  height: 100vh;
}

.backgroundImage {
  background-image: url("../assets/stock.jpg");
  height: 100%;
  object-fit: contain;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.login_box {
  width: 370px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 50px;
  border-radius: 10px;
  box-shadow: 16px 23px 29px -16px rgba(0, 0, 0, 0.22);
}

.login_box h1 {
  float: left;
  font-size: 40px;
  border-bottom: 6px solid var(--primary-color);
  margin-bottom: 50px;
  margin: 0px 0px 30px 0px;
}

.textbox {
  width: 100%;
  overflow: hidden;
  font-size: 18px;
  padding: 10px 0px;
  margin: 18px 0px 18px 0px;
  border: 2px solid #b6bbc4;
  border-radius: 5px;
}

.i {
  float: left;
  text-align: center;
  margin-left: 8px;
}

.inputText {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 18px;
  width: 80%;
  float: left;
  margin: 0 10px;
}

.inputText::placeholder {
  color: #b6bbc4;
}

.btn {
  width: 100%;
  height: 45px;
  border: 0px;
  background-color: var(--primary-color);
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin: 12px 0;
  border-radius: 5px;
  font-weight: bold;
}

.logo {
  height: 70px;
  width: 150px;
  margin-left: 10px;
  padding-bottom: 10px;
  border-bottom: 3px solid var(--primary-color);
}

.logoContainer {
  display: flex;
  justify-content: center;
}
