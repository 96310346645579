/* DASH SAMVITTI NEWS */
.newsCard {
    width: 100%;
    box-sizing: border-box;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
    padding: 10px;
}

.newsCard:hover {
    background-color: #eee;
}

.slogname {
    color: var(--primary-color);
    margin: 0px 0px 5px 0px;
    font-size: 18px;
    cursor: pointer;
}

.category {
    color: #858585;
    font-size: 14px;
    margin: 0px 0px 4px 0px;
}

.dateTimeAndScripCode {
    margin: 6px 0px 0px 0px;
    display: inline-block;
    font-size: 12px;
    background-color: #3fd984;
    padding: 5px 10px 5px 10px;
    border-radius: 20px;
}

.positionsBtn {
    margin: 6px 14px 0px 0px;
    display: inline-block;
    font-size: 12px;
    background-color: #bec923;
    padding: 5px 10px 5px 10px;
    border-radius: 20px;
    cursor: pointer;
}

.historicalBtn {
    margin: 6px 14px 0px 0px;
    display: inline-block;
    font-size: 12px;
    background-color: #f29452;
    padding: 5px 10px 5px 10px;
    border-radius: 20px;
    cursor: pointer;
    color: #2d3250;
}

.positionsBtn {
    border: none;
    outline: none;
}

.historicalBtn {
    border: none;
    outline: none;
}

/* DASH GLOBAL NEWS */