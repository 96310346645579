/* CSS for USERS */
.btnConatiner {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}

.addUserBtn {
  width: 140px;
  height: 45px;
  background-color: var(--primary-color);
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 8px;
  margin: 0px 15px 0px 0px;
}

.addUserBtn:hover {
  background-color: #007233;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.userCard {
  width: calc(33.33% - 20px);
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-right: 20px;
  margin-bottom: 20px;
}

.userCard:last-child {
  margin-right: 0px;
  /* margin-bottom: 0px; */
}

.userCardRight {
  padding: 20px;
}

.userName {
  font-weight: bold;
  font-size: 25px;
  margin: 0px;
}

.userRole {
  font-size: 18px;
  color: var(--primary-color);
  margin: 0px 0px 0px 10px;
}

.userId {
  font-size: 14px;
  color: #666666;
}

.userMail {
  font-size: 14px;
  color: #666666;
}

.btn {
  background-color: var(--primary-color);
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  margin-right: 10px;
}

.btn:hover {
  background-color: #007233;
}

.deleteBtn {
  background-color: rgb(255, 0, 0);
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  margin-right: 10px;
}

/* CSS for ADD USERS */
.label {
  display: block;
  margin-bottom: 10px;
}

.dropdown {
  display: block;
  width: 30%;
  height: 45px;
  padding: 8px;
  border: 2px solid #aaa;
  border-radius: 8px;
  box-sizing: border-box;
  margin-top: 5px;
  font-size: 18px;
  color: var(--primary-color);
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><path d="M6 9L1.5 4h9z" fill="currentColor"/></svg>');
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  background-size: 16px;
}

.dropdown:focus {
  outline: none;
  border: 2px solid var(--primary-color);
}

.input {
  display: block;
  width: 30%;
  height: 45px;
  padding: 8px;
  border: 2px solid #aaaaaa;
  border-radius: 8px;
  box-sizing: border-box;
  margin-top: 5px;
  font-size: 18px;
  color: var(--primary-color);
}

.input:focus {
  outline: none;
  border: 2px solid var(--primary-color);
}

.input::placeholder {
  color: #b6bbc4;
}

.addUserbtn {
  width: 180px;
  height: 45px;
  background-color: var(--primary-color);
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 8px;
  margin: 0px 15px 0px 0px;
}
