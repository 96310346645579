/* Profile Page */

.Container {
  width: 100%;
  height: 30%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-image: url(../assets//background.jpg);
}

.userName {
  display: inline-block;
  font-size: 50px;
  margin: 30px 10px 10px 0px;
}

.userRole {
  display: inline-block;
}

.icon {
  margin-right: 5px;
  color: var(--primary-color);
}

.h3Text {
  font-weight: 400;
}

.btn {
  width: 180px;
  height: 45px;
  background-color: var(--primary-color);
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 8px;
  margin: 0px 15px 0px 0px;
}

/* Profile Edit Details Page */
.label {
  display: block;
  margin-bottom: 10px;
}

.input {
  display: block;
  width: 30%;
  height: 45px;
  padding: 8px;
  border: 2px solid #aaaaaa;
  border-radius: 8px;
  box-sizing: border-box;
  margin-top: 5px;
  font-size: 18px;
  color: var(--primary-color);
}

.input:focus {
  outline: none;
  border: 2px solid var(--primary-color);
}

.input::placeholder {
  color: #b6bbc4;
}

.input[disabled] {
  background-color: #dff0d8;
  color: #888;
  cursor: not-allowed;
  border: 2px solid #ccc;
}
