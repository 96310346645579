.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: #0c1821;
  color: #fff;
  transition: width 0.5s ease;
}

.sidebar.closed {
  width: 60px;
}

.profileDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #324a5f;
  opacity: 1;
  transition: opacity 0.5s;
}

.sidebar.closed .profileDiv {
  opacity: 0;
}

.profileImg {
  width: 50%;
  margin-top: 10px;
  border-radius: 15%;
}

.profileRole {
  color: #999;
  margin-top: 20px;
  margin-bottom: 0px;
  text-transform: uppercase;
}

.profileUserName {
  font-size: 1rem;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 0px;
}

.ul {
  list-style: none;
  padding: 10px;
}

.ulClosed {
  margin-top: 80px;
}

.li {
  margin-bottom: 10px;
}

.navBtn {
  background-color: #0c1821;
  color: #fff;
  padding: 10px 15px;
  width: 100%;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  text-align: left;
  border-radius: 5px;
  display: flex;
  align-items: center;
  transition: background-color 0.5s;
}

.navBtn:hover {
  background-color: var(--primary-color);
}

.navBtnActive {
  background-color: var(--primary-color);
}

.toggleBtn {
  background-color: #0c1821;
  color: #fff;
  border: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 30px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: right 0.3s;
  z-index: 1000;
}

.sidebar.closed .toggleBtn {
  right: 11px;
}

.sidebar.closed .toggleBtn:hover {
  background-color: var(--primary-color);
  border-radius: 5px;
}

.sidebar.closed .navBtn {
  padding: 10px;
}