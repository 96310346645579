.container {
    display: flex;
    height: 100%;
    width: 100%;
}

.section1 {
    margin: 10px;
    flex: 1;
    display: flex;
    flex-direction: column; /* Change to row for horizontal alignment */
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px 20px 0px 0px;
    box-sizing: border-box;
    overflow: auto;
}

.section1Part {
    margin-bottom: 10px;
    border-radius: 18px;
    border: 1px solid black;
    flex: 1; /* Each partition takes up half the space */
    flex-direction: column; /* Optional, based on your content structure */
    align-items: flex-start; /* Optional, for alignment */
    justify-content: flex-start;
    padding: 0px 10px; /* Adjust padding as needed */
    box-sizing: border-box;
    overflow: auto;
    width: 100%; /* For scrolling within each partition */
    overflow-x: hidden;
}

.section2 {
    border: 1px solid black;
    border-radius: 18px;
    flex: 1;
    margin: 10px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px 20px 10px 20px;
    box-sizing: border-box;
    overflow-x: hidden;
}

.heading {
    margin: 0;
}

.card {
    border: 1px solid #ddd;
    padding: 20px;
    margin-top: 20px;
    width: 100%;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-sizing: border-box;
}

.divider {
    width: 1px;
    background-color: #ccc;
    margin: 0px 10px 0px 10px;
}